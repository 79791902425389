// @flow

import React from 'react'
import type { Node } from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import { Heading1 } from '../components'
import { Heading, Paragraph } from '../components/long-form'
import { MediaLink } from '../components/links'

function EmailLink() {
  return (
    <MediaLink href="mailto:info@cottagesofparadisepoint.com">
      info@cottagesofparadisepoint.com
    </MediaLink>
  )
}

function PrivacyPolicyContent() {
  return (
    <div className="pt-8 lg:pt-16">
      <Heading>What information do we collect?</Heading>
      <Paragraph>
        We collect information from you when you fill out a form. When
        registering on our site, you may be asked to enter your: name, e-mail
        address or phone number. You may, however, visit our site anonymously.
      </Paragraph>
      <Heading>What do we use your information for?</Heading>
      <Paragraph>
        Any of the information we collect from you may be used in one of the
        following ways:
      </Paragraph>
      <ul
        // Use the same styles as the <Paragraph> component
        className="mb-4 text-grey-darkest leading-normal pl-5"
        style={{
          listStyle: 'initial',
        }}
      >
        <li className="mb-2">To contact you regarding your inquiry.</li>
        <li className="mb-2">
          Add your information to our mailing list if requested.
        </li>
        <li>
          To improve customer service (your information helps us to more
          effectively respond to your customer service requests and support
          needs)
        </li>
      </ul>
      <Heading>How do we protect your information?</Heading>
      <Paragraph>
        We implement a variety of security measures to maintain the safety of
        your personal information when you enter, submit, or access your
        personal information.
      </Paragraph>
      <Heading>Do we use cookies?</Heading>
      <Paragraph>
        Yes (Cookies are small files that a site or its service provider
        transfers to your computer’s hard drive through your Web browser (if you
        allow) that enables the sites or service providers systems to recognize
        your browser and capture and remember certain information
      </Paragraph>
      <Paragraph>
        We use cookies to compile aggregate data about site traffic and site
        interaction so that we can offer better site experiences and tools in
        the future.
      </Paragraph>
      <Paragraph>
        If you prefer, you can choose to have your computer warn you each time a
        cookie is being sent, or you can choose to turn off all cookies via your
        browser settings. Like most websites, if you turn your cookies off, some
        of our services may not function properly. However, you can still place
        orders over the telephone or by contacting customer service.
      </Paragraph>
      <Heading>Do we disclose any information to outside parties?</Heading>
      <Paragraph>
        We do not sell, trade, or otherwise transfer to outside parties your
        personally identifiable information. This does not include trusted third
        parties who assist us in operating our website, conducting our business,
        or servicing you, so long as those parties agree to keep this
        information confidential. We may also release your information when we
        believe release is appropriate to comply with the law, enforce our site
        policies, or protect ours or others rights, property, or safety.
      </Paragraph>
      <Heading>California Online Privacy Protection Act Compliance</Heading>
      <Paragraph>
        Because we value your privacy we have taken the necessary precautions to
        be in compliance with the California Online Privacy Protection Act. We
        therefore will not distribute your personal information to outside
        parties without your consent.
      </Paragraph>
      <Heading>Children’s Online Privacy Protection Act Compliance</Heading>
      <Paragraph>
        We are in compliance with the requirements of COPPA (Children’s Online
        Privacy Protection Act), we do not collect any information from anyone
        under 13 years of age. Our website, products and services are all
        directed to people who are at least 13 years old or older.
      </Paragraph>
      <Heading>Online Privacy Policy Only</Heading>
      <Paragraph>
        This online privacy policy applies only to information collected through
        our website and not to information collected offline.
      </Paragraph>
      <Heading>Your Consent</Heading>
      <Paragraph>
        By using our site, you consent to our privacy policy.
      </Paragraph>
      <Heading>Changes to our Privacy Policy</Heading>
      <Paragraph>
        If we decide to change our privacy policy, we will update the Privacy
        Policy modification date below.
      </Paragraph>
      <Paragraph>This policy was last modified on Nov 12, 2018</Paragraph>
      <Heading>Contacting Us</Heading>
      <Paragraph>
        If there are any questions regarding this privacy policy you may contact
        us using the information below.
      </Paragraph>
      <div>
        <Paragraph>
          Cottages of Paradise Point <br />
          16970 San Carlos Blvd, Suite 160-107,
          <br />
          Fort Myers Beach, FL 33908
        </Paragraph>
        <Paragraph>
          <EmailLink />
        </Paragraph>
      </div>
      <Heading>Unsubscribe From Mailing List</Heading>
      <Paragraph>
        To unsubscribe from receiving emails with news and rate updates send an
        email to <EmailLink /> with subject:{' '}
        <span className="font-semibold">UNSUBSCRIBE</span>.
      </Paragraph>
    </div>
  )
}

function PrivacyPolicyPage(): Node {
  return (
    <Layout>
      <div>
        <Helmet>
          <title>Cottages of Paradise Point &mdash; Privacy Policy</title>
          <meta name="description" content="Privacy Policy" />
        </Helmet>
        <section className="pb-5">
          <header className="bg-purple text-white">
            <div className="w-full max-w-xl mx-auto py-8 md:py-12 px-8">
              <Heading1 isTitle>
                Cottages of Paradise Point Privacy Policy
              </Heading1>
            </div>
          </header>
          <article>
            <div className="w-full max-w-xl mx-auto px-8">
              <PrivacyPolicyContent />
            </div>
          </article>
        </section>
      </div>
    </Layout>
  )
}

export default PrivacyPolicyPage
