// @flow

import * as React from 'react'
import cx from 'classnames'
import { Heading4 } from '../typography'

type Props = {
  children: React.Node,
  isImportant?: boolean,
}

function Heading({ children, isImportant }: Props): React.Node {
  return (
    <Heading4
      className={cx(
        'text-grey-darkest leading-normal font-bold mb-1',
        isImportant && 'uppercase underline',
      )}
    >
      {children}
    </Heading4>
  )
}
Heading.defaultProps = {
  isImportant: false,
}

export default Heading
