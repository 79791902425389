// @flow

import * as React from 'react'
import { BodyText } from '../typography'

type Props = {
  children: React.Node,
}

function Paragraph({ children }: Props): React.Node {
  return (
    <BodyText className="mb-4 text-grey-darkest leading-normal">
      {children}
    </BodyText>
  )
}

export default Paragraph
